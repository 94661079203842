"use client";
import React from 'react';
import styled from 'styled-components';
import Navbar from '../layout/navbar';
import ContactPage from '../components/Index_Page/contact/page';
import Footer from '../layout/footer';
import AboutPage from '../components/Index_Page/about/page';
import Testimonial from '../components/Index_Page/testimonial/page';
import Howitworks from '../components/Index_Page/howitworks/page';
import KnowMoreComponent from '../components/Index_Page/knowmore/page';
import FeaturePage from '../components/Index_Page/feature/page';
import HeroSection from '../components/Index_Page/hero/page';
import AnimatedSection from '../layout/AnimatedSection';
import StepsToOrder from '../components/Index_Page/stepstoorder/page';
import FeatureClickAway from '../components/Index_Page/feature-click-away/page';
import FeatureAd from '../components/Index_Page/feture-mobile-ad/page';
import BlogsComponent from '../components/Index_Page/blogs/page';
import TopBar from '../layout/topbar';
import StickyIcons from '../layout/sideicon';
import { Box } from '@mui/material';

const Section = styled.section`
  padding: 0px;
`;

export default function HomePage() {
  return (
    <Box sx={{ width: { xs: '425px', sm: '100%', md: '100%' } }}>
      <TopBar />
      <Navbar />
      <StickyIcons />
      <main>
        <HeroSection />
        <AnimatedSection>
          <Section id="features">
            <FeaturePage />
          </Section>
          <Section>
            <FeatureClickAway />
          </Section>
          <Section id="mobile-ad">
            <FeatureAd />
          </Section>
          <Section id="know-more">
            <KnowMoreComponent />
          </Section>
          <Section id="how-it-works">
            <Howitworks />
          </Section>
          <Section id="steps-to-order">
            <StepsToOrder />
          </Section>
          <Section id="testimonial">
            <Testimonial />
          </Section>
          <Section id="about">
            <AboutPage />
          </Section>
          <Section id="blogs">
            <BlogsComponent />
          </Section>
          <Section id="contact">
            <ContactPage />
          </Section>
        </AnimatedSection>
      </main>
      <Footer />
    </Box>
  );
}
