"use client";
import React from 'react';
import Image from 'next/image';
import styled, { keyframes, css } from 'styled-components';
import { Container, Box } from '@mui/material';
import About_img_desktop from '../../../../public/Index_Page/about/about.jpg';
import About_img_mobile from '../../../../public/Index_Page/about/about_mobile.svg';
import { HeadingText, ParagraphText } from '../../../ui/TextFonts';
import { useInView } from 'react-intersection-observer';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedFormWrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  width: 100%;
  justify-content: center;
  animation: ${({ inView }) => (inView ? css`${slideInFromLeft} 1s ease-out` : 'none')};
`;

const AboutSection = styled(Box)`
  padding: 2rem 0px;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 768px) {
    padding: 0rem;
  }
`;

const ImageWrapper = styled(Box)`
  position: relative;
  width: 62%;
  height: auto;

  @media (max-width: 768px) {
    display: block;
    width: 425px;
    margin-left: -25px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const ImageWrapperDesktop = styled(Box)`
  position: relative;
  width: 50%;
  height: auto;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

export default function AboutPage() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box component="div" sx={{ mt: 8, px: { xs: 0, sm: 3, md: 8 } }} id="about">
      <Container maxWidth="xl">
        <AboutSection>
          <ImageWrapper>
            <Image
              src={About_img_mobile}
              alt="About image mobile"
              layout="responsive"
            />
          </ImageWrapper>
          <ImageWrapperDesktop>
            <Image
              src={About_img_desktop}
              alt="About image desktop"
              layout="responsive"
            />
          </ImageWrapperDesktop>
          <AnimatedFormWrapper ref={ref} inView={inView}>
            <HeadingText>
              About Us
            </HeadingText>
            <ParagraphText>
              At Pappad, we're on a mission to make your life easier. Whether you're craving a delicious meal or need fresh groceries, we've got you covered.
            </ParagraphText>
            <ParagraphText>
              Our platform connects you with the best local vendors and delivery partners, ensuring that you get what you need, when you need it.
            </ParagraphText>
          </AnimatedFormWrapper>
        </AboutSection>
      </Container>
    </Box>
  );
}
