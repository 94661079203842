import React from 'react';
import Image from 'next/image';
import { Box, Container } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import First_img from '../../../../public/Index_Page/steps/step1.png';
import Second_img from '../../../../public/Index_Page/steps/step2.png';
import Third_img from '../../../../public/Index_Page/steps/step3.png';
import Arrow_img from '../../../../public/Index_Page/steps/arrow.svg';
import { HeadingText } from '../../../ui/TextFonts';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { variables } from '../../../styles/variables';

const slideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const AnimatedImage = styled(Box)`
 opacity: 0;
  ${props => props.inView && css`
    animation: ${slideInFromLeft} 1s ease-out forwards;
  `}
`

const ImageBox = styled.div`
  background-color: white;
  padding: 2;
  border-radius: 20px;
  display: flex;
  justify-content: center;
`;

const ImageView = styled(Image)`
height: 741.599609375px; 
width: 443.599609375px;

@media (max-width: 1024px) {
    height: 350px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  overflow: hidden;

  @media (max-width: 768px) {
    display: none;
  }
`;

export default function StepsToOrder() {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    return (
        <Box component="div" sx={{ mt: 8, px: { xs: 2, sm: 3, md: 5 } }} id="steps-to-order">
            <HeadingText style={{ textAlign: 'center' }}>
                Order in <span style={{ color: `${variables.primaryColor}` }}>3 Simple Steps</span>
            </HeadingText>
            <Container maxWidth="xl">
                <Box sx={{
                    mt: { xs: 0, sm: 3, md: 6 },
                    display: { xs: 'block', md: 'none' },
                    '.swiper-pagination-bullets': {
                        bottom: '10px',
                    },
                    '.swiper-pagination-bullet': {
                        backgroundColor: '#000',
                    },
                    '.swiper-pagination-bullet-active': {
                        background: `${variables.primaryColor}`
                    },
                }}>
                    <Swiper
                        modules={[Pagination, Navigation, Autoplay]}
                        spaceBetween={30}
                        slidesPerView={1}
                        breakpoints={{
                            768: {
                                slidesPerView: 3,
                            },
                        }}
                        pagination={{ clickable: true }}
                        navigation
                        autoplay={{ delay: 5000 }}
                    >
                        <SwiperSlide>
                            <ImageBox>
                                <ImageView
                                    src={First_img}
                                    alt="first_img"
                                />
                            </ImageBox>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ImageBox>
                                <ImageView
                                    src={Second_img}
                                    alt="second_img"
                                />
                            </ImageBox>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ImageBox>
                                <ImageView
                                    src={Third_img}
                                    alt="third_img"
                                />
                            </ImageBox>
                        </SwiperSlide>
                    </Swiper>
                </Box>

                <ImageContainer ref={ref}>
                    <AnimatedImage inView={inView} style={{ animationDelay: '0.5s' }}>
                        <Image
                            src={First_img}
                            alt={First_img}
                            layout="responsive"
                        />
                    </AnimatedImage>
                    <AnimatedImage inView={inView} style={{ animationDelay: '1.25s', display: 'flex' }}>
                        <Image
                            src={Arrow_img}
                            alt="arrow"
                            layout="responsive"
                        />
                    </AnimatedImage>
                    <AnimatedImage inView={inView} style={{ animationDelay: '1.75s' }}>
                        <Image
                            src={Second_img}
                            alt={Second_img}
                            layout="responsive"
                        />
                    </AnimatedImage>
                    <AnimatedImage inView={inView} style={{ animationDelay: '2.25s', display: 'flex' }}>
                        <Image
                            src={Arrow_img}
                            alt="arrow"
                            layout="responsive"
                        />
                    </AnimatedImage>
                    <AnimatedImage inView={inView} style={{ animationDelay: '2.75s' }}>
                        <Image
                            src={Third_img}
                            alt={Third_img}
                            layout="responsive"
                        />
                    </AnimatedImage>
                </ImageContainer>
            </Container>
        </Box>
    );
}
