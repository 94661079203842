import styled from 'styled-components';

const StyledIframe = styled.iframe`
  height: 431px;
  width: 100%;
  border-radius: 0px;
  padding: 24px;
  margin-top: 20px;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    border-radius: 10px;
    padding: 0px;
    margin-top: 40px;
    margin-bottom: 60px;
  }
`;

const YouTubeIframe = () => (
  <StyledIframe
    src="https://www.youtube.com/embed/DDVbTGxvgJw"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

export default YouTubeIframe;
