import React from 'react';
import { Box, Container, Typography, Tooltip } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import testimonialsData from "../../../static-data/testimonial.json";
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { HeadingText } from '../../../ui/TextFonts';
import styled, { keyframes, css } from 'styled-components';
import { variables } from '../../../styles/variables';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedCard = styled(Box)`
  animation: ${({ inView, index }) =>
        inView ? css`${fadeInUp} 0.5s ease-out ${index * 0.2}s` : 'none'};
`;

export default function Testimonial() {
    const { testimonials } = testimonialsData;

    const truncateDescription = (description) => {
        const words = description.split(' ');
        const cond = words.slice(0, 25).join(' ') + '....';
        return words.length > 25 ? `"${cond}"` : `"${description}"`;
    };

    return (
        <Box component="div" sx={{ mt: 8, px: { xs: 0, sm: 3, md: 8 } }} id="testimonial">
            <Container maxWidth="xl">
                <Box>
                    <Box textAlign="center" mb={4}>
                        <HeadingText>
                            Testimonials
                        </HeadingText>
                    </Box>

                    <Box sx={{
                        mt: 6,
                        position: 'relative',
                        '.swiper-pagination-bullets': {
                            bottom: '10px',
                        },
                        '.swiper-pagination-bullet': {
                            backgroundColor: '#000',
                            width: '10px',
                            height: '10px',
                        },
                        '.swiper-pagination-bullet-active': {
                            background: `${variables.primaryColor}`
                        },
                        '.swiper-button-next': {
                            color: `${variables.primaryColor}`,
                        },
                        '.swiper-button-prev': {
                            color: `${variables.primaryColor}`,
                        },
                    }}>
                        <Swiper
                            modules={[Pagination, Navigation, Autoplay]}
                            spaceBetween={20}
                            slidesPerView={1}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 3,
                                },
                            }}
                            pagination={{ clickable: true }}
                            navigation
                            loop
                            // autoplay={{ delay: 5000 }}
                            style={{ paddingBottom: '50px' }}
                        >
                            {testimonials.map((data, index) => (
                                <SwiperSlide key={index} style={{ display: 'flex', alignItems: 'stretch' }}>
                                    <AnimatedCard inView={true} index={index} sx={{
                                        backgroundColor: '#fff4e7',
                                        p: 3,
                                        borderRadius: "20px",
                                        height: '100%',
                                    }}>
                                        <Box sx={{ height: { xs: '150px', sm: '200px' } }}>
                                            <Tooltip
                                                title={data.description}
                                                arrow
                                                placement="top"
                                                disableInteractive={false}
                                            >
                                                <Typography sx={{
                                                    color: "#1e1e1e",
                                                    fontFamily: '"Poppins", Helvetica',
                                                    fontSize: { xs: '18px', sm: '20px' },
                                                    fontWeight: '600',
                                                    textAlign: 'center',
                                                    whiteSpace: 'pre-wrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    cursor: 'pointer',
                                                    maxHeight: { xs: '9rem', sm: '11rem' },
                                                    // textAlign: 'justify'
                                                }}>
                                                    {truncateDescription(data.description)}
                                                </Typography>
                                            </Tooltip>
                                        </Box>

                                        <Box style={{ alignItems: 'flex-start', padding: '0px 10px' }}>
                                            <Typography variant="h6" sx={{ fontFamily: '"Poppins", Helvetica', fontSize: '16px', fontWeight: '700', color: `${variables.primaryColor}` }}>
                                                {data.name}
                                            </Typography>
                                            <Typography sx={{ fontFamily: '"Poppins", Helvetica', fontSize: '14px', fontWeight: '400', color: '#343434' }}>
                                                {data.designation}
                                            </Typography>
                                        </Box>
                                    </AnimatedCard>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
