import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const AnimatedDiv = styled.div`
  ${(props) => props.inView && css`
    animation: ${slideIn} 1s ease-out;
  `}
`;

const AnimatedSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <AnimatedDiv ref={ref} inView={inView}>
      {children}
    </AnimatedDiv>
  );
};

export default AnimatedSection;
