"use client";
import React from 'react';
import Image from 'next/image';
import styled, { keyframes, css } from 'styled-components';
import { Box } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import Ad_Left_img from "../../../../public/Index_Page/features/ad1_mobile.svg";
import Ad_Right_img from "../../../../public/Index_Page/features/ad2_mobile.svg";
import { HeadingText, ParagraphText } from '../../../ui/TextFonts';
import features from '../../../static-data/features.json';
import { variables } from '../../../styles/variables';

const leftBoxItems = features.leftBox;
const rightBoxItems = features.rightBox;


const slideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('/Index_Page/features/advertisement.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    display: none;
    height: auto;
  }
`;

const MobileViewContainer = styled.div`
  @media (max-width: 768px) {
    display: block;
    margin-left: -15px;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  position: relative;
  padding: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

const SideBox = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const AnimatedBox = styled(Box)`
  background: #FFE8CC;
  border-radius: 10px;
  padding: 16px 24px;
  opacity: 0;

  ${props => props.inView && css`
    animation: ${slideInFromLeft} 1s ease-out forwards;
  `}
`;

const AnimatedBoxRight = styled(Box)`
  background: #FFE8CC;
  border-radius: 10px;
  padding: 16px 24px;
  opacity: 0;

  ${props => props.inView && css`
    animation: ${slideInFromRight} 1s ease-out forwards;
  `}
`;

export default function FeatureAd() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box component="div" sx={{ mt: 8, px: { xs: 0, sm: 3, md: 0 } }} id="mobile-ad">
      <BackgroundContainer>
        <BoxContainer ref={ref}>
          <SideBox>
            {leftBoxItems.map((box, index) => (
              <AnimatedBox
                key={index}
                inView={inView}
                style={{ animationDelay: `${0.5 + index * 0.5}s` }}
              >
                <HeadingText style={{ color: `${variables.primaryColor}`, fontSize: '15px', fontWeight: '700' }}>{box.title}</HeadingText>
                <ParagraphText style={{ color: "#000000", fontSize: '13px', fontWeight: '400' }}>{box.content}</ParagraphText>
              </AnimatedBox>
            ))}
          </SideBox>
          <SideBox>
            {rightBoxItems.map((box, index) => (
              <AnimatedBoxRight
                key={index}
                inView={inView}
                style={{ animationDelay: `${0.5 + index * 0.5}s` }}
              >
                <HeadingText style={{ color: `${variables.primaryColor}`, fontSize: '15px', fontWeight: '700' }}>{box.title}</HeadingText>
                <ParagraphText style={{ color: "#000000", fontSize: '13px', fontWeight: '400' }}>{box.content}</ParagraphText>
              </AnimatedBoxRight>
            ))}
          </SideBox>
        </BoxContainer>
      </BackgroundContainer>

      <MobileViewContainer>
        <Image src={Ad_Left_img} alt="Left ad" layout="responsive" />
        <Image src={Ad_Right_img} alt="Right ad" layout="responsive" />
      </MobileViewContainer>
    </Box>
  );
}
