"use client";
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import styled, { keyframes, css } from 'styled-components';
import { Container, TextField, Button, Box, Snackbar } from '@mui/material';
import Contact_img_desktop from '../../../../public/Index_Page/contact/contact.jpg';
import Contact_img_mobile from '../../../../public/Index_Page/contact/contact_mobile.svg';
import { HeadingText, ParagraphText } from '../../../ui/TextFonts';
import { useInView } from 'react-intersection-observer';
import { variables } from '../../../styles/variables';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedFormWrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  width: 100%;
  ${({ inView }) =>
    inView &&
    css`
      animation: ${slideInFromLeft} 1s ease-out;
    `}
`;

const ContactSection = styled(Box)`
  padding: 2rem 0px;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 768px) {
    padding: 0rem;
  }
`;

const ImageWrapper = styled(Box)`
  position: relative;
  width: 62%;
  height: auto;

  @media (max-width: 768px) {
    display: block;
    width: 425px;
    margin-left: -25px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const ImageWrapperDesktop = styled(Box)`
  position: relative;
  height: auto;

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 767px) and (max-width: 1024px) {
    display: block;
    width: 400px;
  }

  @media (min-width: 1024px) {
    display: block;
    width: 600px
  }
`;

const BoxContainer = styled(Box)`
  padding: 64px;

@media (max-width: 1024px) {
    padding: 0rem;
  }
`

const StyledImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export default function ContactPage() {
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();

    const res = await fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, city, number, message }),
    });

    const result = await res.json();
    if (res.ok) {
      setLoading(false);
      setStatus(result.message);
      setOpenSnackbar(true);
      setName('');
      setCity('');
      setNumber('');
      setMessage('');
    } else {
      setLoading(false);
      setStatus(`Error: ${result.message}`);
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <BoxContainer component="div" sx={{ mt: 8, px: { xs: 2, sm: 3, md: 5 } }} id="contact">
      <Container maxWidth="xl">
        <ContactSection>
          <ImageWrapper>
            <Image
              src={Contact_img_mobile}
              alt="Contact image mobile"
              layout="responsive"
            />
          </ImageWrapper>
          <ImageWrapperDesktop>
            <StyledImage
              src={Contact_img_desktop}
              alt="Contact image desktop"
            />
          </ImageWrapperDesktop>
          <AnimatedFormWrapper ref={ref} inView={inView}>
            <HeadingText>
              Become a  <span style={{ color: `${variables.primaryColor}`}}> Pappad Partner </span>
            </HeadingText>
            <ParagraphText>
              Join the Pappad family and unlock new opportunities. Whether you’re a local business or a delivery professional, we have the tools and support to help you succeed.
            </ParagraphText>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <TextField
                style={{ marginTop: '10px' }}
                label="City"
                variant="outlined"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
              <TextField
                style={{ marginTop: '10px' }}
                label="Number"
                variant="outlined"
                fullWidth
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
              <TextField
                style={{ marginTop: '10px' }}
                label="Message"
                variant="outlined"
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              <Button style={{ background: `${variables.primaryColor}`, marginTop: '30px' }} disabled={loading} variant="contained" type="submit" fullWidth>
                BECOME A PARTNER
              </Button>
            </form>
          </AnimatedFormWrapper>
        </ContactSection>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={status}
          action={
            <Button color="inherit" onClick={handleCloseSnackbar}>
              Close
            </Button>
          }
        />
      </Container>
    </BoxContainer>
  );
}
