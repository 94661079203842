"use client";
import React from 'react';
import { Box, Container } from '@mui/material';
import { HeadingText, ParagraphText } from '../../../ui/TextFonts';
import YouTubeIframe from './iFrame';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedHeading = styled(HeadingText)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  ${props => props.inView && css`
    animation: ${slideUp} 1s ease-out forwards;
  `}
`;

const AnimatedParagraph = styled(ParagraphText)`
  opacity: 0;
  padding: 10px 350px;
  animation: ${fadeIn} 1s ease-out forwards;
  ${props => props.inView && css`
    animation: ${slideUp} 1s ease-out forwards;
  `}

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export default function Howitworks() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box component="div" sx={{ mt: 8, px: { xs: 2, sm: 3, md: 5 } }} id="how-it-works">
      <Container maxWidth="xl">
        <Box ref={ref} textAlign="center">
          <AnimatedHeading inView={inView}>
            How it Works
          </AnimatedHeading>
          <AnimatedParagraph inView={inView}>
            Experience the simplicity of Pappad in just a few steps. Watch our quick video to see how we bring your everyday essentials to your doorstep.
          </AnimatedParagraph>
          <YouTubeIframe />
        </Box>
      </Container>
    </Box>
  );
}
