import React from 'react';
import { IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const StickyIcons = () => {
    return (
        <span container style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
            <IconButton href="https://api.whatsapp.com/send?phone=919778220860" target='_blank' className="icon whatsapp" style={{ background: '#2BB564', cursor: 'pointer', height: '3.375rem', width: '3.375rem' }}>
                <WhatsAppIcon style={{ fontSize: '36px', color: 'white' }} />
            </IconButton>
        </span>
    );
};

export default StickyIcons;
